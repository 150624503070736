'use client';
import { motion } from 'framer-motion';
import { useGetServiceListingCategoriesQuery } from '@/app/services/listing/service/ServiceListingService';
import {
  faBoltLightning,
  faChildReaching,
  faComputer,
  faGavel,
  faHollyBerry,
  faKitchenSet,
  faPaintRoller,
  faPeopleCarryBox,
  faPersonDigging,
  faSoap,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Image } from '@nextui-org/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import SecureEmploymentImg2 from '../../../../public/images/home-page/SecureEmploymentImg2.png';

const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

interface SecureEmploymentProps {
  searchServiceData: any;
}

const services = [
  { icon: faHollyBerry, title: '⁠Événementiel & Décoration' },
  { icon: faChildReaching, title: 'Education & Formation' },
  { icon: faKitchenSet, title: 'Cuisine & Pâtisserie' },
  { icon: faComputer, title: 'Informatique & Numérique' },
  { icon: faPeopleCarryBox, title: 'Déménagement & Immobilier' },
  { icon: faBoltLightning, title: 'Electronique & Électricité' },
  { icon: faSoap, title: '⁠Nettoyage' },
  { icon: faPersonDigging, title: 'Construction & Rénovation' },
  { icon: faPaintRoller, title: '⁠Beauté' },
  { icon: faGavel, title: 'Services Juridiques' },
];

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'lightgrey' : 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: 'lightgrey',
      color: 'black',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'black',
  }),
};

const SecureEmployment = ({ searchServiceData }: SecureEmploymentProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const [error, setError] = useState<any>(null);
  const router = useRouter();
  const { data: categories } = useGetServiceListingCategoriesQuery({});
  const [servicesList, setServicesList] = useState<any>();

  const handleSearchService = (option: { value: string; label: string }) => {
    if (option.value) {
      const resultList = searchServiceData.filter((result: any) =>
        result.category?.name.toLowerCase().includes(option.value.toLowerCase())
      );
      setServicesList(resultList);
    }
  };

  const handleSearch = () => {
    try {
      const query = new URLSearchParams();

      if (servicesList.length > 0) {
        query.append('serviceType', servicesList[0].category.id.toString());
        router.push(`/services-listing?${query.toString()}`);
      } else {
        throw new Error('Please select a service category before searching.');
      }
    } catch (error) {
      setError('Pas de service correspondant a votre recherche.');
    }
  };
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className='container mx-auto bg-black text-white md:rounded-lg px-5 lg:p-10 py-20 flex flex-col md:flex-row md:min-h-[890px]'>
      <div className="md:w-1/2 flex flex-col gap-8">
        <motion.h1
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.75 }}
        className="text-[28px] lg:text-[35px] font-bold ">
          <span className="text-red-500">Trouvez</span> en un clin d'œil des
          professionnels fiables pour répondre à vos besoins!
        </motion.h1>
        <p className="text-semibold text-[18px] pb-2">
          Accédez en toute simplicité aux services dont vous avez besoin,
          proposés par des entreprises de confiance près de chez vous.
        </p>
        <div className="flex flex-col md:flex-row gap-5 items-center my-10 md:my-0">
          {isClient && (
            <Select
              // aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              styles={customStyles}
              className="w-full !text-black"
              placeholder="Quel service recherchez-vous?"
              options={categories?.data.map((service: any) => ({
                value: service.name,
                label: service.name,
              }))}
              onChange={(event: any) => handleSearchService(event)}
            />
          )}
          <Button
            className="font-bold text-black bg-white "
            size="lg"
            onClick={handleSearch}
          >
            Rechercher
          </Button>
        </div>
        <div>
          {error && (
            <div className="flex items-center gap-2">
              <p style={{ color: 'rgb(255, 0, 0)' }}>{error}</p>
              <Link href="/services-listing" className="underline text-white">
                Continuer
              </Link>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-10 hidden md:block">
          <h3 className="text-2x underline">Services Populaires</h3>
          <ul className="w-full flex flex-col gap-5 md:grid lg:grid-cols-2 md:justify-between md:items-center">
            {services.map((service, index) => (
              <li
                key={index}
                className="flex items-center gap-4 py-3 text-sm hover:underline"
              >
                <FontAwesomeIcon icon={service.icon} className="mr-2" />
                <span className="font-semibold">{service.title}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="hidden md:w-1/2 p-4 min-h-full lg:flex lg:items-center justify-end">
        <Image
          src={SecureEmploymentImg2.src}
          alt="secure-employment-image"
          className="w-full max-h-[800px] h-full object-cover rounded-lg mt-8 grayscale-50"
        />
      </div>

    </motion.div>
  );
};

export default SecureEmployment;
