export default function formatDateText(dateString: string): string {
    const publishedDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - publishedDate.getTime();

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
        return 'À l\'instant';
    } else if (minutes < 60) {
        return `Il y a ${minutes === 1 ? '1 minute' : `${minutes} minutes`}`;
    } else if (hours < 24) {
        return `Il y a ${hours === 1 ? '1 heure' : `${hours} heures`}`;
    } else if (days < 7) {
        return `Il y a ${days === 1 ? '1 jour' : `${days} jours`}`;
    } else if (weeks < 4) {
        return `Il y a ${weeks === 1 ? '1 semaine' : `${weeks} semaines`}`;
    } else if (months < 12) {
        return `Il y a ${months === 1 ? '1 mois' : `${months} mois`}`;
    } else {
        return `Il y a ${years === 1 ? '1 an' : `${years} ans`}`;
    }
}
