'use client';
import { motion, AnimatePresence } from 'framer-motion';
import { faHandshakeAngle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemListingCard from './ItemListingCard';
import { useRef } from 'react';

interface FeatureItemProps {
  data: any;
  title: string;
  type: 'SERVICE' | 'PROPERTY' | 'VEHICLE';
}

function FeaturedItem({ data, title, type }: FeatureItemProps) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <>
      {data?.length > 0 ? (
        <AnimatePresence>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            exit={{ opacity: 0 }}
            className='container mx-auto flex flex-col lg:gap-6 px-5 lg:px-0 relative'
          >
            <h1 className="flex gap-4 items-center text-3xl lg:text-3xl font-bold">
              <FontAwesomeIcon icon={faHandshakeAngle} />
              <span>{title}</span>
            </h1>

            <div className="relative">
              {/* Left Scroll Button */}
              <button
                onClick={scrollLeft}
                className="absolute left-0 z-10 p-2 bg-gray-900 text-white rounded-full shadow-lg top-1/2 transform -translate-y-1/2"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              {/* Cards Container */}
              <div
                ref={scrollRef}
                className="flex overflow-hidden overflow-x-auto gap-5 py-5 scroll-smooth scrollbar-none"
                style={{ scrollbarWidth: 'none' }}
              >
                {data?.map((listing: any, index: number) => (
                  <div className="flex-shrink-0 lg:px-4" key={index}>
                    <ItemListingCard item={listing} type={type} />
                  </div>
                ))}
              </div>

              {/* Right Scroll Button */}
              <button
                onClick={scrollRight}
                className="absolute right-0 z-10 p-2 bg-gray-900 text-white rounded-full shadow-lg top-1/2 transform -translate-y-1/2"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </motion.div>
        </AnimatePresence>
      ) : null}
    </>
  );
}

export default FeaturedItem;