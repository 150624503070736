'use client';
import CustomSelect from "@/app/components/forms/form-inputs/CustomSelect";
import { Button, Spinner } from "@nextui-org/react";
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { motion } from 'framer-motion';
interface ILocationProps {
    id: number;
    name: string;
    icon: string | undefined;
}

interface ServicesSearchFormProps {
    cityList: any[],
    serviceCategoriesList: any[]
}

export default function ServicesSearchForm({ cityList, serviceCategoriesList }: ServicesSearchFormProps) {
    const router = useRouter();

    const [loading, setLoading] = useState<boolean>(false);
    const [location, setLocation] = useState<ILocationProps | null>(null);
    const [serviceType, setServiceType] = useState<ILocationProps | null>(null);

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (!location && !serviceType) {
            setLoading(false);
            return;
        }

        const query = new URLSearchParams();
        if (location) query.append('location', location.name);
        if (serviceType) query.append('serviceType', (serviceType.id).toString());

        router.push(`/services-listing?${query.toString()}`);
        setLoading(false);
    };

    return (
        <motion.form
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            onSubmit={handleSearch} className="space-y-3 min-w-[350px] min-h-[220px] flex flex-col gap-3 mt-14 lg:mt-0">
            {!loading && cityList && serviceCategoriesList ? <>
                <div className="mb-4 w-full">
                    <CustomSelect
                        name="location"
                        placeholder="Sélectionnez une ville"
                        value={location}
                        handleChange={(value: ILocationProps) => setLocation(value)}
                        optionData={cityList}
                    />
                </div>
                <div className="mb-4 w-full">
                    <CustomSelect
                        name="serviceType"
                        placeholder="Sélectionnez le type de service"
                        value={serviceType}
                        handleChange={(value: ILocationProps) => setServiceType(value)}
                        optionData={serviceCategoriesList}
                    />
                </div>
            </> : <div className="flex flex-col gap-2 items-center justify-center h-full min-h-[60%]">
                <h1>searching...</h1>
                <Spinner color="default" labelColor="foreground" />
            </div>}
            <Button
                isLoading={loading}
                type="submit"
                className="text-white bg-black text-sm h-12"
            >
                {!loading ? 'Rechercher' : 'Loading...'}
            </Button>
        </motion.form>
    );
}
