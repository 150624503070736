export const tabTexts = {
    property: {
        title: 'Trouvez Votre Maison de Rêve',
        subtitle:
            'Explorez les meilleures propriétés en un clic. Commencez dès maintenant !',
        backgroundImage:
            "url('https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
    },

    services: {
        title: 'Services Fiables, Vie Simplifiée',
        subtitle:
            'Découvrez des prestataires triés sur le volet. Facilitez votre quotidien !',
        backgroundImage:
            "url('https://images.unsplash.com/photo-1600062115240-9fc0e39eadd0?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
    },
    cars: {
        title: 'Véhicules Dignes de Confiance',
        subtitle:
            'Trouvez le véhicule idéal en quelques clics. Simplifiez vos déplacements !',
        backgroundImage:
            "url('https://images.unsplash.com/photo-1528659997310-540c4945fa73?q=80&w=3046&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
    },
};