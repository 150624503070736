'use client';
import formatDateText from '@/app/Utils/formatDateText';
import getWhatsAppUrl from '@/app/Utils/getWhatsAppUrl';
import {
  faAngleLeft,
  faAngleRight,
  faBath,
  faBed,
  faCalendar,
  faCar,
  faCircleCheck,
  faCity,
  faExpand,
  faEye,
  faFire,
  faGasPump,
  faLocationDot,
  faPhone,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Spinner } from '@nextui-org/react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import UserIcon from '../../../../../public/images/Avatar.jpg';
import Placeholder from '../../../../../public/images/placeholder-featured.jpg';

export interface IProfileCardProps {
  item: any;
  type: 'SERVICE' | 'PROPERTY' | 'VEHICLE';
}

const formatDate = (dateString: string) => {
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export default function ItemListingCard({ item, type }: IProfileCardProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Add state to track loading

  const handleContactUs = () => {
    let whatsAppUrl;
    const currentURL =
      typeof window !== 'undefined' ? window.location.href : '';

    const prefilledMessage = `Salut, j'aimerai avoir plus de détails sur l'annonce que j'ai vu sur CasaKonnect - ${item?.title || item?.companyName}, avec pour Numéro d'identification ${item?.listingNumber}. Voice le lien: ${currentURL} `;
    try {
      whatsAppUrl = getWhatsAppUrl(
        item?.users_permissions_user.phone_number,
        prefilledMessage
      );
      window.open(whatsAppUrl, '_blank');
    } catch (error) {
      console.error('Error calling getWhatsAppUrl:', error);
    }
  };

  const nextImage = () => {
    if (item?.images?.length) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % item?.images?.length
      );
    }
  };

  const prevImage = () => {
    if (item?.images?.length) {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + item?.images?.length) % item?.images?.length
      );
    }
  };

  const handleButtonClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <motion.div className="max-w-sm mx-auto w-full min-w-[300px] lg:w-[350px] h-[480px]">
      <div className="flex flex-col w-full bg-white shadow-2xl rounded-lg overflow-hidden">
        <div className="w-full relative">
          {item?.image_cover || item?.avatar ? (
            <div
              className="h-44 bg-cover bg-center filter grayscale-50"
              style={{ backgroundImage: `url(${item?.image_cover?.url})` }}
            >
              <div className="absolute inset-0 bg-black opacity-30"></div>
              <div className="relative flex flex-col items-center h-full justify-center">
                <div className="relative w-24 h-24 mb-2">
                  {item?.avatar ? (
                    <Image
                      src={item?.avatar.url}
                      alt="featured-image"
                      width={200}
                      height={200}
                      className="rounded-full border-2 border-white h-full w-full mt-2"
                    />
                  ) : (
                    <Image
                      src={UserIcon}
                      alt="featured-image"
                      width={200}
                      height={200}
                      className="rounded-full border-2 border-white h-full w-full mt-2"
                    />
                  )}
                </div>
                {item?.featured && (
                  <span className="absolute top-2 left-2 bg-yellow-500 text-white text-[12px] flex items-center justify-center py-1 px-3 rounded-full font-bold">
                    <FontAwesomeIcon icon={faFire} className="pr-1" /> Vedette
                  </span>
                )}
                {item?.isVerified && (
                  <span className="absolute top-2 right-2 bg-green-500 text-white text-[12px] flex items-center justify-center py-1 px-3 rounded-full font-bold">
                    <FontAwesomeIcon icon={faCircleCheck} className="pr-1" />
                    Vérifié
                  </span>
                )}
                {item?.viewCount && (
                  <small className="absolute bottom-2 right-2 bg-gray-100 p-1 rounded-md">
                    <FontAwesomeIcon icon={faEye} className="pr-1" />
                    {item?.viewCount}
                  </small>
                )}
                <h3
                  className="px-5 font-semibold text-[20px] text-center text-white  h-12 bg-transparent shadow-lg"
                  style={{ textShadow: '0 2px 4px rgba(0,0,0,0.5)' }}
                >
                  {item?.companyName}
                </h3>
                <p className="text-small text-white mb-2">{item?.specialty}</p>
              </div>
            </div>
          ) : item?.images && item?.images[0].url ? (
            <div className="relative group cursor-pointer">
              <Image
                src={item?.images[currentImageIndex].url}
                alt="items-images"
                width={300}
                height={300}
                className="h-52 w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
              />
              <span
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black text-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer transition duration-300 hover:bg-gray-700 text-lg"
                onClick={prevImage}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>
              <span
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black text-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer transition duration-300 hover:bg-gray-700"
                onClick={nextImage}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <small
                className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md shadow-md font-bold"
                style={{ zIndex: 10 }}
              >
                ${item?.price}
              </small>
              {item?.featured && (
                <span className="absolute top-2 left-2 bg-yellow-500 text-white text-[12px] flex items-center justify-center py-1 px-3 rounded-full font-bold">
                  <FontAwesomeIcon icon={faFire} className="pr-1" /> Vedette
                </span>
              )}
              {item?.viewCount && (
                <small className="absolute bottom-2 right-2 bg-gray-100 p-1 rounded-md">
                  <FontAwesomeIcon icon={faEye} className="pr-1" />
                  {item?.viewCount}
                </small>
              )}
              {item?.title && (
                <h3
                  className="absolute bottom-2 mx-5 text-[15px] text-white text-center 
                          bg-black bg-opacity-50 p-2 rounded-md shadow-lg font-bold
                          
                          text-shadow-[0_2px_4px_rgba(0,0,0,0.6)]"
                  style={{
                    textShadow: '0 2px 4px rgba(0,0,0,0.6)',
                  }}
                >
                  {item?.title}
                </h3>
              )}
            </div>
          ) : (
            <div className="relative group cursor-pointer">
              <Image
                src={Placeholder}
                alt="items-images"
                width={300}
                height={300}
                className="h-52 w-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105"
              />{' '}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 px-5 py-3">
          <h3 className="text-2xl font-medium text-black">{item?.name}</h3>
          <div className="rounded text-gray-700 flex flex-col gap-2">
            {/* PROPERTY FEATURED ITEM */}
            {type === 'PROPERTY' && (
              <>
                <div>
                  <div className="flex items-center justify-between gap-4">
                    {item.numberOfBedrooms && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faBed} /> {item.numberOfBedrooms}
                      </span>
                    )}
                    {item.numberOfBathrooms && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faBath} />{' '}
                        {item.numberOfBathrooms}
                      </span>
                    )}
                    {item.dimensions > 0 && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faExpand} /> {item.dimensions}
                      </span>
                    )}
                    {item.hasWifi && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faWifi} />
                      </span>
                    )}
                    {item.property_listing_type?.type && (
                      <span className=" bg-gray-600 w-fit self-end text-white text-[12px] flex items-center justify-center py-1 px-3 rounded-full text-right">
                        {item.property_listing_type?.type}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* VEHICLE FEATURED ITEM */}

            {type === 'VEHICLE' && (
              <>
                <div>
                  <div className="flex justify-between items-center">
                    {item?.car_maker?.name && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faCar} />
                        {item?.car_maker?.name}
                      </span>
                    )}

                    {item?.year_of_manufacturing && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faCalendar} />
                        {item?.year_of_manufacturing}
                      </span>
                    )}
                    {item?.car_fuel_type?.name && (
                      <span className="py-2 flex text-black gap-2 items-center">
                        <FontAwesomeIcon icon={faGasPump} />
                        {item?.car_fuel_type?.name}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* SERVICE FEATURED ITEM */}
            {type === 'SERVICE' && (
              <>
                <div className="items-center">
                  <h3 className="text-center">{item?.category?.name}</h3>
                </div>
                <div className="flex justify-between items-center">
                  {item?.serviceProviderNumber && (
                    <span className="py-2 flex text-black gap-2 items-center">
                      <FontAwesomeIcon icon={faPhone} />
                      {item?.serviceProviderNumber}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <Divider className="my-1" />
          <ul className="w-full flex flex-col my-1 gap-2">
            <li className="flex gap-2 items-center">
              <FontAwesomeIcon icon={faCity} className="font-bold" />
              <span>{item?.city.name}</span>
              |
              <FontAwesomeIcon icon={faLocationDot} className=" font-bold" />
              <span>{item?.suburb?.name}</span>
            </li>
            {type !== 'VEHICLE' && (
              <li className="flex gap-2 items-center"></li>
            )}
            <li className="w-full flex justify-between items-center">
              <small> Publié: {formatDateText(item?.publishedAt)}</small>
              <FaWhatsapp
                size="25"
                onClick={handleContactUs}
                className="font-bold"
              />
            </li>
          </ul>
        </div>
        <div className="bg-gray-100 p-5 flex justify-between items-center">
          <div className="w-full flex justify-center items-center">
            <Button
              size="md"
              className="w-full bg-black text-white"
              onClick={handleButtonClick}
            >
              {isLoading ? (
                <Spinner color="default" size="sm" />
              ) : (
                <Link
                  href={
                    type === 'PROPERTY'
                      ? `/properties-listing/${item?.slug}`
                      : type === 'SERVICE'
                        ? `/services-listing/${item?.slug}`
                        : type === 'VEHICLE'
                          ? `/cars-listing/${item?.slug}`
                          : ''
                  }
                  className="w-full bg-black text-white py-2 px-4 rounded"
                >
                  Voir +
                </Link>
              )}
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
