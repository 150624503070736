'use client';
import CustomSelect from '@/app/components/forms/form-inputs/CustomSelect';
import { Button, Spinner } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { motion } from 'framer-motion';

interface ILocationProps {
  id: number;
  name: string;
  icon: undefined | string;
}

interface PropertySearchFormProps {
  cityList: any[];
  propertyTypeList: any[];
  propertyTypes: any[];
}

export default function PropertySearchForm({
  cityList,
  propertyTypeList,
}: PropertySearchFormProps) {

  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<ILocationProps | null>(null);
  const [propertyType, setPropertyType] = useState<ILocationProps | null>(null);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!location && !propertyType) {
      console.error('Please select location and property type.');
      setLoading(false);
      return;
    }

    const query = new URLSearchParams();
    if (location) query.append('location', location.name);
    if (propertyType) query.append('propertyType', propertyType.name);

    setLoading(false);
    router.push(`/properties-listing?${query.toString()}`);
  };

  return (
    <motion.form
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
      onSubmit={handleSearch}
      className="space-y-3 min-w-[350px] min-h-[220px] flex flex-col gap-3"
    >
      {!loading ? (
        <>
          <div className="mb-4 w-full">
            <CustomSelect
              name="location"
              placeholder="Sélectionnez une ville"
              value={location}
              handleChange={(value: ILocationProps) => setLocation(value)}
              optionData={cityList}
            />
          </div>
          <div className="mb-4 w-full">
            <CustomSelect
              name="property_type"
              placeholder="À vendre ou à louer ?"
              value={propertyType}
              handleChange={(value: ILocationProps) => setPropertyType(value)}
              optionData={propertyTypeList}
            />
          </div>

        </>
      ) : (
        <div className="flex flex-col gap-2 items-center justify-center h-full min-h-[60%]">
          <h1>searching...</h1>
          <Spinner color="default" labelColor="foreground" />
        </div>
      )}
      <Button
        isLoading={loading}
        type="submit"
        className="text-white bg-black text-sm h-12"
      >

        {!loading ? 'Rechercher' : 'Loading...'}
      </Button>
    </motion.form>
  );
}
