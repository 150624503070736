'use client';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { tabTexts } from '../../../Utils/home-page-content/TabTexts';
import CarsSearchForm from './search-form/CarsSearchForm';
import PropertySearchForm from './search-form/PropertySearchForm';
import ServicesSearchForm from './search-form/ServicesSearchForm';

interface HeroSectionProps {
  searchFilterData: {
    cityList: any[];
    propertyTypeList: any[];
    propertyTypes: any[];
    carMakersList: any[];
    serviceCategoriesList: any[];
  };
}

const HeroSection = ({ searchFilterData }: HeroSectionProps) => {
  type TabKey = 'property' | 'services' | 'cars';
  const [activeTab, setActiveTab] = useState<TabKey>('property');
  const { title, subtitle, backgroundImage } = tabTexts[activeTab];

  const handleTabClick = (tab: TabKey) => {
    setActiveTab(tab);
  };

  const renderActiveForm = () => {
    switch (activeTab) {
      case 'property':
        return (
          <PropertySearchForm
            cityList={searchFilterData.cityList}
            propertyTypes={searchFilterData.propertyTypes}
            propertyTypeList={searchFilterData.propertyTypeList}
          />
        );
      case 'cars':
        return (
          <CarsSearchForm
            cityList={searchFilterData.cityList}
            carMakersList={searchFilterData.carMakersList}
          />
        );
      case 'services':
        return (
          <ServicesSearchForm
            cityList={searchFilterData.cityList}
            serviceCategoriesList={searchFilterData.serviceCategoriesList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className="hero-section bg-cover bg-center bg-no-repeat mt-6"
      style={{ backgroundImage: backgroundImage }}
    >
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center gap-8 my-16">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.75 }}
            className="flex-1 md:order-2 bg-black bg-opacity-50 p-6 rounded-lg shadow-lg backdrop-blur-sm">
            <motion.h1
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.75 }}
              className="text-[28px] font-bold text-white mb-4">{title}</motion.h1>
            <motion.h3
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 0.75 }}
              className="text-white  font-bold text-[18px]">{subtitle}</motion.h3>
          </motion.div>
          <div className="flex-1 md:order-1">
            <div className="search-bar my-5">
              <div className="tabs flex space-x-3 mb-5">
                <motion.button
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ ease: 'easeInOut', duration: 0.75 }}
                  onClick={() => handleTabClick('property')}
                  className={`text-sm font-bold ${activeTab === 'property' ? 'underline text-red-500' : 'hover:underline'}`}
                >
                  Propriété
                </motion.button>
                <motion.button
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ ease: 'easeInOut', duration: 0.75 }}
                  onClick={() => handleTabClick('cars')}
                  className={`text-sm font-bold ${activeTab === 'cars' ? 'underline text-red-500' : 'hover:underline'}`}
                >
                  Voiture
                </motion.button>
                <button
                  onClick={() => handleTabClick('services')}
                  className={`text-sm font-bold ${activeTab === 'services' ? 'underline text-red-500 ' : 'hover:underline'}`}
                >
                  Service
                </button>
              </div>
              {renderActiveForm()}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
