'use client';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton: React.FC = () => {
    const phoneNumber = '+243990525056';
    const message = "Bonjour CasaKonnect! Je suis intéressé par vos services et j’aimerais en savoir plus. Pouvez-vous m’aider ? Merci d’avance !";

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const handleClick = () => {
        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'click', {
                event_category: 'whatsApp',
                event_lable: 'WhatsaApp Button Click',
                value: 1,
            });
        }
    }
    return (
        <a href={whatsappLink}
            target='_blank'
            rel="noopener noreferrer"
            className='fixed bottom-4 right-4 bg-green-900 text-white p-4 rounded-full shadow-lg flex items-center justify-center hover:bg-green-800 transition-colors hover:scale-110 animate-bounce'
            aria-label='Contact us on WhatsApp'
            onClick={handleClick} // Track click event
        >
            <FaWhatsapp size={30} />
        </a>
    );
};

export default WhatsAppButton; 