'use client';
import { Button } from '@nextui-org/react';
import UseRegisterModal from '@/app/hooks/useRegisterModal';

const RegisterComponent = () => {
  const registerModal = UseRegisterModal();

  const handleOpenRegisterModal = () => {
    registerModal.onOpen();
  };

  return (
    <div>
      <Button onClick={handleOpenRegisterModal} size='lg' color='danger'>S'inscrire</Button>
    </div>
  );
};

export default RegisterComponent;
