import { BaseService } from "../../BaseService";


const ServiceServices = BaseService.injectEndpoints({
  endpoints: (build) => ({

    getServices: build.query({
      query: ({ cityId, serviceTypes }) => {
        let queryString = '/service-listings/?populate=*';

        if (cityId) {
          queryString += `filters[city][id][$eq]=${cityId}&`;
        }

        if (serviceTypes) {
          queryString += `filters[category][id][$eq]=${serviceTypes}&`;
        }

        return queryString;
      },
    }),

    getEducationLevels: build.query({
      query: () => "educations",
    }),

    getServicesByStatus: build.query({
      query: (status) => `service-listings?filters[status][name][$eq]=${status}?populate=*`,
    }),

    getServiceListingCategories: build.query({
      query: () => "service-listing-categories",
    }),

    showService: build.query({
      query: (id) => `service-listings/${id}?populate=*`,
    }),

    createServiceListing: build.mutation({
      query: (data) => ({
        url: '/service-listings?populate=*',
        method: 'POST',
        body: data
      })
    }),

    updateServiceListing: build.mutation({
      query: ({id,data}) => ({
        url: `/service-listings/${id}?populate=*`,
        method: 'PUT',
        body: data,
        headers:{
          'Content-Type':'application/json',
          'Accept':'application/json'
        }
      }),
    }),

    deleteServiceListing: build.mutation({
      query: (id) => ({
        url: `/service-listings/${id}?populate=*`,
        method: 'DELETE',
      }),
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetEducationLevelsQuery,
  useShowServiceQuery,
  useGetServiceListingCategoriesQuery,
  useGetServicesByStatusQuery,
  useGetServicesQuery,
  useCreateServiceListingMutation,
  useUpdateServiceListingMutation,
  useDeleteServiceListingMutation,
} = ServiceServices